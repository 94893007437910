<template>
  <li class="secondary-menu-item flex-column ai-c jc-c cursor-pointer"
    :class="{ active: currentMenuId === menu?.id }"
    @click="handleClick">
    <inline-svg
      width="16px"
      height="16px"
      class="secondary-menu-item__icon"
      :src="require(`@/assets/` + customPath + `/` + menu?.icon)"></inline-svg>
    <p class="secondary-menu-item__tag default-text">{{ menu?.name }}</p>
  </li>
</template>
<script>
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    menu: {
      type: Object,
      required: true,
    },
    currentMenuId: {
      type: Number
    },
    customPath: {
      type: String,
      default: 'icons/kyp-icons'
    }
  },
  emits: ['handle-click'],
  methods: {
    handleClick() {
      this.$emit('handle-click', this.menu?.id);
    }
  },
});
</script>
<style lang="scss" scoped>
 .secondary-menu-item {
    gap: 0.3rem;
    // padding-bottom: 1rem;

    &__tag {
      color: rgba(12, 15, 74, 0.6);
      font-feature-settings: "clig" off, "liga" off;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      text-align: center;
      white-space: nowrap;
      position: relative;
    }
    &__icon {
      :deep(path) {
        fill: rgba(12, 15, 74, 0.4);
        fill-opacity: 1;
      }
    }
 }
 .active {
    // border-bottom: 2px solid #4B4BFF;

    .secondary-menu-item__tag {
      color: #0C0F4A;

      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 2px;
        background: #4B4BFF;
        bottom: 0;
        left: 0;
      }
    }
    .secondary-menu-item__icon {
      :deep(path) {
        fill: #0C0F4A;
        fill-opacity: 1;
      }
    }
 }
</style>
