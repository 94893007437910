<template>
    <ul class="secondary-menu flex-row ai-c">
      <slot></slot>
    </ul>
</template>
<script>
import { defineComponent } from 'vue';

export default defineComponent({
});
</script>
<style lang="scss" scoped>
@use "../../../assets/scss/mixins/media-query" as *;
@use "../../../assets/scss/variables/layout" as *;

  .secondary-menu {
    border-bottom: 1px solid rgba(12, 15, 74, 0.2);
    gap: 2rem;
    overflow-x: auto;
  }

  @include media-md-max-width() {
    .secondary-menu {
      margin: 0;
      padding-left: 1rem;
    }
  }
</style>
